.mainContainer {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: 100%;
  margin-top: 64px;
  max-width: 600px;
}

.dataContainer {
  display: flex;
  flex-direction: inherit;
  margin-block-end: 20px;
}

.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.bio {
  text-align: center;
  color: gray;
  margin-top: 16px;
}

.waveButton {
  cursor: pointer;
  margin-top: 16px;
  padding: 8px;
  border: 0;
  border-radius: 5px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 16px;
}
