.wave {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  margin-block-end: 8px;
}

.wave-message {
  margin-block: 6px;
}

.wave-address {
  font-weight: bold;
  margin-block-start: 0;
}

.owner.wave {
  margin-inline-start: auto;
  background-color: #00b;
  color: white;
}

.wave-timestamp {
  text-align: end;
  font-size: 0.8em;
  color: #999;
}
